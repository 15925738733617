import Keycloak from "keycloak-js";

const _kc = new Keycloak('/keycloak.json');

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
    _kc.init({
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
        pkceMethod: 'S256',
    })
        .then(() => {
            onAuthenticatedCallback();
            refresh();
        })
        .catch(console.error);
};

const doLogin = _kc.login;

const doRegister = _kc.register;

const doResetPass = () => window.location.replace("https://auth.brandsky.io/auth/realms/Brandsky/login-actions/reset-credentials?client_id=BrandskyReact");

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback) =>
    _kc.updateToken(5)
        .then(successCallback)
        .catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;

const getFirstName = () => _kc.tokenParsed?.given_name;

const getSecondName = () => {
    return _kc.tokenParsed?.family_name;
};

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const refresh = () => {
    if (isLoggedIn()){
        setTimeout(() => {
            updateToken(() => {
                refresh();
            });
        }, 60000);
    }
}

const UserService = {
    initKeycloak,
    doLogin,
    doLogout,
    isLoggedIn,
    getToken,
    updateToken,
    getUsername,
    hasRole,
    getFirstName,
    getSecondName,
    doRegister,
    doResetPass
};

export default UserService;