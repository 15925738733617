import React from "react";
import ReactDOM from "react-dom/client";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import SettingsProvider from "contexts/settingsContext";
import App from "./App";

import "nprogress/nprogress.css";
import "react-quill/dist/quill.snow.css";
import "simplebar-react/dist/simplebar.min.css";
import "pure-react-carousel/dist/react-carousel.es.css";
import "./__fakeData__";
import UserService from "./utils/UserService";
import HttpService from "./utils/axios";
import UserProvider from "./contexts/UserContext";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const renderApp = () => root.render(
    <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SettingsProvider>
            <UserProvider>
                <App/>
            </UserProvider>
        </SettingsProvider>
    </LocalizationProvider>
);


UserService.initKeycloak(renderApp);
HttpService.configure();