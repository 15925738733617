import Icons from "icons/sidebar";

const index = [
  { title: "Dashboard", Icon: Icons.DashboardIcon, path: "/", children: [] },
  {
    title: "Admin Center",
    Icon: Icons.AdminEcommerceIcon,
    children: [
      { name: "Produkt Liste", path: "/product-list" },
      { name: "Neues Produkt erstellen", path: "/create-product" },
      { name: "Fragmente", path: "/fragments" },
      { name: "Order Management", path: "/order-management" },
    ],
  },
  {
    title: "Ecommerce",
    Icon: Icons.EcommerceIcon,
    children: [
      { name: "Alle Produkte", path: "/products" },
      { name: "Meine Produkte", path: "/my-products" },
    ],
  }
];

export default index;
