import {TabContext, TabList, TabPanel} from "@mui/lab";
import {Button, Divider, Grid, RadioGroup, styled, Switch, Tab, useTheme} from "@mui/material";
import AppModal from "components/AppModal";
import React, {ChangeEvent, FC, SyntheticEvent, useEffect, useState} from "react";
import AppAvatar from "../../../components/avatars/AppAvatar";
import AppTextField from "../../../components/input-fields/AppTextField";
import {H5, Tiny} from "../../../components/Typography";
import AppRadio from "../../../components/AppRadio";
import FormControlLabel from "@mui/material/FormControlLabel";
import ImageUpload from "../../../page-sections/admin-ecommerce/add-product/ImageUpload";
import FlexBox from "../../../components/flexbox/FlexBox";
import HttpService from "../../../utils/axios";
import {useNavigate} from "react-router-dom";
import {KeyboardArrowDown} from "@mui/icons-material";

// custom styled components
const StyledTab = styled(Tab)(({theme}) => ({
    fontSize: 13,
    minHeight: "auto",
    color: theme.palette.text.primary,
}));

const StyledAppModal = styled(AppModal)(({theme}) => ({
    padding: 30,
    maxWidth: 800,
    minWidth: 800,
}));

// ------------------------------------------------------------------
type StockModalProps = {
    openModal: boolean;
    closeModal: () => void;
};

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
    "& .MuiTypography-root": {fontSize: 12, fontWeight: 600},
}));
// ------------------------------------------------------------------

const StockModal: FC<StockModalProps> = ({
                                             openModal, closeModal
                                         }) => {
    const navigate = useNavigate();
    const [stockLocation, setStockLocation] = useState("");
    const [amount, setAmount] = useState<number|string|undefined>(0);

    const [stockLocations, setStockLocations] = useState([]);

    const getStockLocations = () => {
        HttpService.getAxiosClient()
            .get("https://api.tomorrowbrand.de/v1/stock/")
            .then((res) => {
                const data = res.data;
                setStockLocations(data);
            })
            .catch((error) => console.log(error));
    }

    useEffect(() => {
        getStockLocations();
    }, []);

    useEffect(() => {
        // @ts-ignore
        setStockLocation(stockLocations?.[0]?.barcode);
        // @ts-ignore
        setAmount(Number(stockLocations.find(sL => sL.barcode === stockLocations[0].barcode)?.amount) ?? 0);
    }, stockLocations);

    const handleClose = () => {
        closeModal();
    }

    const isNullOrEmpty = (str: string | undefined) => {
        return str === undefined || str === ''
    };

    const checkIfReady = () => {
        let ready = true;
        if (stockLocation === undefined)
            ready = false;
        if (typeof amount !== "number")
            ready = false;

        return ready;
    }

    const updateStock = () => {

        return HttpService.getAxiosClient()
            .post("https://api.tomorrowbrand.de/v1/stock/" + stockLocation + "?amount=" + amount)
            .then(response => {
                return response.data;
            }).catch(error => {
                return error;
            }).finally(() => {
                window.location.reload();
            });
    }

    return (
        <StyledAppModal open={openModal} handleClose={handleClose}>
            <TabContext value='1'>
                <TabPanel value="1">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <H5>Bestand verwalten</H5>
                        </Grid>
                        <Grid item xs={6}>
                            <AppTextField
                                select
                                fullWidth
                                label="Stock Location wählen"
                                SelectProps={{
                                    native: true,
                                    IconComponent: KeyboardArrowDown,
                                }}
                                value={stockLocation}
                                onChange={(e) => {
                                    setStockLocation(e.target.value);
                                    // @ts-ignore
                                    setAmount(Number(stockLocations.find(sL => sL.barcode === e.target.value)?.amount) ?? 0);
                                }}
                            >
                                <option value={undefined} disabled>Bitte wählen</option>
                                {stockLocations.map((entry: {barcode: string, id: number}) => <option
                                    value={entry.barcode}>{entry.barcode}</option>)}
                            </AppTextField>
                        </Grid>
                        <Grid item xs={6}>
                            <AppTextField label="Menge" fullWidth required value={amount} type='number'
                                // @ts-ignore
                                          onBlur={() => setAmount(Number(Number(amount).toFixed(0)))}
                                // @ts-ignore
                                          onChange={(e) => setAmount(e.target.value)}/>
                        </Grid>

                        <Grid item xs={12}>
                            <FlexBox flexWrap="wrap" gap={2}>
                                <Button variant="contained" disabled={!checkIfReady()} onClick={() => updateStock()}>
                                    Bestand anpassen</Button>
                            </FlexBox>
                        </Grid>
                    </Grid>
                </TabPanel>
            </TabContext>
        </StyledAppModal>
    );
};

export default StockModal;
