import {IconButton, Tooltip} from "@mui/material";
import AppAvatar from "components/avatars/AppAvatar";
import FlexBox from "components/flexbox/FlexBox";
import {H6} from "components/Typography";
import Delete from "icons/Delete";
import Edit from "icons/Edit";
import {Column} from "react-table";
import HttpService from "../../../utils/axios";

const getColumnShape = (setActiveFragment: (fragment: any) => void): Column[] => [
    {
        Header: "Fragment",
        accessor: "name",
        Cell: ({row: {original}}: any) => {
            return (
                <FlexBox alignItems="center" gap={1}>
                    <AppAvatar
                        src={original.imageUrl}
                        sx={{borderRadius: "10%", width: 50, height: 50}}
                    />
                    <H6>{original.name}</H6>
                </FlexBox>
            );
        },
    },
    {
        Header: "Bestand",
        accessor: "stock",
        Cell: ({row: {original}}: any) => {
            return (
                <FlexBox alignItems="center" gap={1}>
                    <Tooltip title={formTooltipContent(original)} className="pointerHover">
                        <p>{original.stock}</p>
                    </Tooltip>
                </FlexBox>
            );
        },
    },
    {
        Header: "SKU",
        accessor: "sku",
    },
    {
        Header: "Aktion",
        accessor: "",
        Cell: ({row: {original}}: any) => {
            return (
                <FlexBox alignItems="center" gap={1}>
                    <Tooltip title={"Dieses Fragment bearbeiten"} className="pointerHover">
                        <IconButton>
                            <Edit color='warning' onClick={() => {
                                setActiveFragment(original)
                            }}/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={"Das Löschen von Fragmenten ist unwiderruflich"} className="pointerHover">
                        <IconButton>
                            <Delete color='error' onClick={() => {
                                HttpService.getAxiosClient()
                                    .delete('https://api.tomorrowbrand.de/v1/products/fragments/' + original.id)
                                    .then(res => {
                                        window.location.reload();
                                    })
                                    .catch(error => console.error(error));
                            }}/>
                        </IconButton>
                    </Tooltip>
                </FlexBox>
            );
        },
    },
];

const formTooltipContent = (json: any) => {
    if (json.locations === null)
        return "Kein Bestand";
    let content = "";
    json.locations.forEach((loc: { barcode: string, amount: number }) => content = content + "\n" + loc.barcode + ": " + loc.amount);
    return content;
}

export default getColumnShape;
