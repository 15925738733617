import {FC, useEffect} from "react";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {CssBaseline, ThemeProvider} from "@mui/material";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import RTL from "components/RTL";
import useSettings from "hooks/useSettings";
import {createCustomTheme} from "./theme";
import routes from "./routes";
import "./i18n";
import HttpService from "./utils/axios";
import toast from "react-hot-toast";
import UserProvider from "./contexts/UserContext";
import useAccount from "./hooks/useAccount";
import UserService from "./utils/UserService";

const App: FC = () => {
    const router = createBrowserRouter(routes());
    const {settings} = useSettings();
    const {user, saveUser} = useAccount();

    const theme = createCustomTheme({
        theme: settings.theme,
        direction: settings.direction,
        responsiveFontSizes: settings.responsiveFontSizes,
    });

    useEffect(() => {
        HttpService.getAxiosClient()
            .get("https://api.tomorrowbrand.de/v1/users/")
            .then((res) => {
                const data = res.data;
                saveUser({...data})
            })
            .catch((error) => console.log(error));
    }, [UserService.isLoggedIn()]);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <RouterProvider router={router}/>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
