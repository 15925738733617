import {Button, Tab} from "@mui/material";
import {Box, styled} from "@mui/system";
import FlexBetween from "components/flexbox/FlexBetween";
import FlexBox from "components/flexbox/FlexBox";
import IconWrapper from "components/IconWrapper";
import {H5} from "components/Typography";
import Add from "icons/Add";
import ShoppingBasket from "icons/ShoppingBasket";
import ProductListView from "page-sections/admin-ecommerce/product-list/list-view";
import React, {SyntheticEvent, useEffect, useState} from "react";
import StepModal from "./stepModal";
import FragmentsModal from "./fragmentsModal";
import FragmentListView from "../../page-sections/admin-ecommerce/fragment-list";
import HttpService from "../../utils/axios";
import StockModal from "./stockModal";

//  styled components
const HeadingWrapper = styled(FlexBetween)(({theme}) => ({
    gap: 8,
    flexWrap: "wrap",
    [theme.breakpoints.down(453)]: {
        "& .MuiButton-root": {order: 2},
        "& .MuiTabs-root": {
            order: 3,
            width: "100%",
            "& .MuiTabs-flexContainer": {justifyContent: "space-between"},
        },
    },
}));

const FragmentsList = () => {
    const [openModal, setOpenModal] = useState(false);
    const [openStockModal, setOpenStockModal] = useState(false);
    const [activeFragment, setActiveFragment] = useState(undefined);

    return (
        <>
            <FragmentsModal
                openModal={openModal || activeFragment !== undefined}
                closeModal={() => {
                    setOpenModal(false);
                    setActiveFragment(undefined);
                }}
                fragment={activeFragment}
            />
            <StockModal
                openModal={openStockModal}
                closeModal={() => setOpenStockModal(false)}
            />
            <Box pt={2} pb={4}>
                <HeadingWrapper>
                    <FlexBox gap={0.5} alignItems="center">
                        <IconWrapper>
                            <ShoppingBasket sx={{color: "primary.main"}}/>
                        </IconWrapper>
                        <H5>Alle Fragmente</H5>
                    </FlexBox>

                    <FlexBox gap={0.5} alignItems="center">
                        <Button variant="contained" startIcon={<Add/>} onClick={() => setOpenModal(true)}>
                            Fragment hinzufügen
                        </Button>
                        <Button variant="contained" color='secondary' startIcon={<Add/>} onClick={() => setOpenStockModal(true)}>
                            Bestand verwalten
                        </Button>
                    </FlexBox>
                </HeadingWrapper>
                {// @ts-ignore
                    <FragmentListView setActiveFragment={setActiveFragment}/>
                }
            </Box>
        </>
    );
};

export default FragmentsList;
