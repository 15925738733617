import useLocalStorage from "hooks/useLocalStorage";
import { createContext, ReactNode } from "react";
import { THEMES } from "../constants";
import {array} from "yup";

export type userTypes = {
  address: object|undefined;
  brands: object|[];
  firstName: string;
  id: number;
  refinedProducts: object|[];
  secondName: string;
  share: number;
  username: string;
  verified: boolean;
  mailAddress: string;
  ustIdNr: string;
};

const initialSettings: userTypes = {
  address: undefined,
  brands: [],
  firstName: "",
  id: 0,
  refinedProducts: [],
  secondName: "",
  share: 70,
  username: "",
  verified: false,
  mailAddress: "",
  ustIdNr: ""
};

export const UserContext = createContext({
  user: initialSettings,
  saveUser: (arg: userTypes) => {},
});

// component props type
type UserProviderProps = {
  children: ReactNode;
};

const UserProvider = ({ children }: UserProviderProps) => {
  const { data: user, storeData: setStoreSettings } = useLocalStorage(
    "user",
    initialSettings
  );

  const saveUser = (userInfo: userTypes) => {
    setStoreSettings(userInfo);
  };

  return (
    <UserContext.Provider value={{ user, saveUser }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
